.p-datatable .p-datatable-thead {
    height: 50px; /* Set the desired height */
    position: sticky;
    top: 0;
    background-color: grey;
    color:#FFF;
    z-index: 100;
}
.p-datatable .p-column-filter{
    padding-right:50px ;
} 
.p-datatable{
    border-radius: 10px;
}
/* .p-paginator {
    background: #fff;
    color: #1e293b;
    border: solid #e9ecef;
    border-width: 0;
    padding: .5rem 1rem;
    border-radius: 6px
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    background-color: rgba(0, 0, 0, 0);
    border: 0 none;
    color: #1e293b;
    min-width: 3rem;
    height: 3rem;
    margin: .143rem;
    transition: box-shadow .2s;
    border-radius: 50%
} */

/* Paginator Styles */
.p-paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
}

.p-paginator .p-paginator-pages {
    display: flex;
    align-items: center;
}

.p-paginator .p-paginator-page {
    padding: 8px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.p-paginator .p-paginator-page:hover {
    background-color: green;
    color: #fff;
}

.p-paginator .p-paginator-current {
    font-weight: bold;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-last {
    background-color: green;
    color: #fff;
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.p-paginator .p-paginator-next:hover,
.p-paginator .p-paginator-prev:hover {
    background-color: #1a66a5;
}
